import { getDocs, collection } from 'firebase/firestore'
import React from 'react'
import { FBFirestore } from 'services/firebaseApiKey'

export default function useGetCards() {
    const [cards, setCards] = React.useState<any[]>([])
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const getCards = React.useCallback(async ()=> {
      try {
        setError('')
        setLoading(true)
        const querySnapshot = await getDocs(collection(FBFirestore, "anuncios"));
  
        querySnapshot.forEach((doc) => {
          setCards((cards) => {
            const newData = doc.data();
            newData.id = doc.id;
  
            const isDuplicate = cards.some((card) => card.titulo === newData.titulo);
            if (!isDuplicate) {
              return [...cards, newData];
            }
            return cards;
          });
        });
      } catch (e) {
        setError("Erro ao acessar dados no Firestore: " + e);
      } finally {
        setLoading(false)
      }
      },[])


  return {
    cards, loading, error, getCards
  }
}
