import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import NavMenu from './components/NavMenu'
import styles from './PaginaComum.module.scss'
import { UserContext } from 'contexts/UserContext';

function PaginaComum() {
  const { loggedIn, loading}=React.useContext(UserContext)  
  if (!loggedIn && !loading) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <main className={styles.paginaComum}>        
          <Outlet />
          <NavMenu />
      </main>
    )  
  }
}

export default PaginaComum