import { sendPasswordResetEmail } from "firebase/auth";
import { FBAuth } from "../firebaseApiKey";

export default async function resetPassword( email: any ) {
  
    try {
        await sendPasswordResetEmail(FBAuth, email);
        alert("E-mail de redefinição de senha enviado com sucesso.");
        return true;
      } catch (error) {
       alert("Erro ao enviar o e-mail de redefinição de senha: " +error);
        return false;
      }
     
}
