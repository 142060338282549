import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import RoutesAPP from "./RoutesAPP";
import ScrollToTop from "component/ScrollToTop";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const isMobileDevice = window.matchMedia("(max-width: 768px)").matches;

if (isMobileDevice) {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop />
        <RoutesAPP />
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <div className="desktop" >
          Esta aplicação é somente exibida em dispositivos móveis.
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
}
