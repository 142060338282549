import React, { useEffect, useState } from "react";
//import FsLightbox from "fslightbox-react";
import styles from "./MaisInfo.module.scss";
import { FaChevronLeft } from "react-icons/fa";
import { BiSolidUser } from "react-icons/bi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BiPhoneCall, BiSolidShareAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { FBFirestore } from "services/firebaseApiKey";
import IProdutoServico from "interfaces/IProdutoServico";
import PaginaDeErro from "pages/PaginaDeErro";
import BtnPadrao from "component/BtnPadrao";
import { CardSearchContext } from "contexts/CardSearchContext";

function MaisInfo() {
  const [anuncio, setAnuncio] = useState<IProdutoServico>();
  //const [toggler, setToggler] = useState(false);

  //const [lightboxImage, setLightboxImage] = useState<string | null>(null);

  // const openLightbox = (image: string) => {
  //   setLightboxImage(image);
  // };

  // //const closeLightbox = () => {
  //   setLightboxImage(null);
  // };

  const { setConfrade}=React.useContext(CardSearchContext)

  const ir = useNavigate();
  const pagianAtual = useLocation().pathname;
  let id = pagianAtual.replace("/maisInfo/", "");

  const shareViaWhatsApp = () => {
    const currentURL = window.location.href;
    const message = encodeURIComponent('Confira esta página: ' + currentURL);

    const whatsappURL = `https://api.whatsapp.com/send?text=${message}`;
    window.open(whatsappURL);
  };

  const handleWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?phone=${anuncio?.whatsapp}`, "_blank");
  };

  const handleCall = () => {
    window.location.href = `tel:${anuncio?.telefone}`;
  };

  async function pegaInfo(id: string) {
    const infoRef = doc(FBFirestore, "anuncios", id);

    try {
      const infos = await getDoc(infoRef);

      if (infos.exists()) {
        const dadosDoAnuncio: IProdutoServico = {
          id: infos.id,
          aprovado: infos.get("aprovado"),
          idCriador: infos.get("idCriador"),
          titulo: infos.get("titulo"),
          descricao: infos.get("descricao"),
          img: infos.get("img"),
          numeroComercial: infos.get("numeroComercial"),
          whatsapp: infos.get("whatsapp"),
          categoria: infos.get("categoria"),
          cep: infos.get("cep"),
          confradeNome: infos.get("confradeNome"),
          cidade: infos.get("cidade"),
          telefone: infos.get("telefone"),
          imgExtra: infos.get("imgExtra"),
          aprovadoPor: infos.get("aprovadoPor"),
          endereco: infos.get("endereco"),
          mostraEndereco: infos.get("mostraEndereco"),
          palavrasChave: infos.get("palavrasChave"),
          tipo: infos.get("tipo"),
        };
        setAnuncio(dadosDoAnuncio);
      } else {
        console.log("O anúncio com ID", id, "não foi encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar o anúncio:", error);
    }
  }

  useEffect(() => {
    pegaInfo(id);
  }, [id]);

  const divStyle = {
    background: `url(${anuncio && anuncio.img ? anuncio.img : 'assets/imgs/Confrarqui_logojpeg.jpeg'}) center/cover no-repeat`,
  };

  return (
    anuncio !== undefined ?
      <section className={styles.maisInfo}>
        <div className={styles.maisInfo__header}>
          <button
            className={styles.maisInfo__header__btnRetorno}
            onClick={() => ir("/home")}
          >
            <FaChevronLeft />
          </button>
          <div className={styles.maisInfo__header__nome}>
            <BiSolidUser />
            <span>{anuncio.confradeNome}</span>
          </div>
        </div>

        <div style={divStyle} className={styles.maisInfo__banner}></div>

        {/* AJUSTAR ESTILO */}
        {anuncio.imgExtra && anuncio.imgExtra.length > 0 && (
          <div className={styles.maisInfo__imgExtra}>
            <h2>Imagens Extras</h2>
            {anuncio.imgExtra.map((imgExtra, index) => (
              <div key={index} className={styles.maisInfo__imgDiv__imgExtra}>
                <img
                  key={index}
                  src={imgExtra}
                  alt={`Imagem Extra ${index}`}
                 />
              </div>
            ))}
          </div>
        )}
        <div className={styles.maisInfo__titulo}>
          <h2>{anuncio.titulo}</h2>
        </div>
        <div className={styles.maisInfo__categoria}>
          <h2>Categoria: </h2>
          <p>{anuncio.categoria}</p>
        </div>
        <div className={styles.maisInfo__endereco}>
          <h2>Endereço: </h2>
          <p>{`${anuncio.cidade}, ${anuncio.cep}`}</p>
        </div>
        <div className={styles.maisInfo__descricao}>
          <h2>Descricao</h2>
          <p>{anuncio.descricao}</p>
        </div>
        <div className={styles.maisInfo__whats}>
          <h2>
            <AiOutlineWhatsApp />
          </h2>
          <p>{anuncio.whatsapp}</p>
        </div>
        <div className={styles.maisInfo__telefone}>
          <h2>
            <BiPhoneCall />
          </h2>
          <p>{anuncio.telefone}</p>
        </div>
        <BtnPadrao type="button" onClick={() => {
          setConfrade(anuncio.confradeNome)
          ir('/home')
        }} >Mais anúncios de {anuncio.confradeNome}</BtnPadrao>
        <footer className={styles.maisInfo__footer}>
          <button onClick={shareViaWhatsApp}><BiSolidShareAlt /></button>
          { anuncio.whatsapp &&
          <button onClick={handleWhatsApp}><AiOutlineWhatsApp /></button>
          }
          { anuncio.telefone &&

          <button onClick={handleCall}><BiPhoneCall /></button>
          }
          </footer>
      </section>
      : <PaginaDeErro />
  );
}

export default MaisInfo;
