import React from 'react'
import styles from "./SemCartas.module.scss"
import {MdSearchOff} from 'react-icons/md'

interface SemCartasProps {
  carta: string
}

export default function SemCartas({carta}: SemCartasProps) {
  return (
      <div className={styles.semCartas}>
        <MdSearchOff className={styles.semCartas__icon } />
        <h1>Nenhum {carta} encontrado</h1>
      </div>
  )
}