import React, { useState, useRef } from 'react'
import styles from './FormCadastro.module.scss'
import { FBFirestore } from 'services/firebaseApiKey';
import { doc, getDoc } from "firebase/firestore";
import { storage } from 'services/firebaseApiKey';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import TagContainer from 'component/TagContainer';
import BtnPadrao from 'component/BtnPadrao';
import MultiFileUploader from 'component/MultiFileUploader';
import ModalMessage from "component/ModalMessage";

import createServico from '../../../services/items/createItem'
import { UserContext } from 'contexts/UserContext';

function FormCadastro() {
  const { user}=React.useContext(UserContext)
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [whatsapp, setWhatsapp] = useState(user?.telefone);
  const [telefone, setTelefone] = useState(user?.telefone);
  const [endereco, setEndereco] = useState('');
  const [productCategories, setProductCategories] = useState<any>()
  const [serviceCategories, setServiceCategories] = useState<any>()
  const [categorieSelected, setCategorieSelected] = useState('')
  const [tipo, setTipo] = useState(String)
  const [categories, setCategories] = useState<string[]>()
  const [tags, setTags] = useState<string[]>([]);
  const [modalAberto, setModalAberto] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorDescricao, setErrorDescricao] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [errorWhatsapp, setErrorWhataspp] = useState(false);
  const [errorTelefone, setErrorTelefone] = useState(false);
  const [errorEndereco, setErrorEndereco] = useState(false);
  const [errorTag, setErrorTag] = useState(false);
  const [errorCategorias, setErrorCategorias] = useState(false);
  const [warning, setWarning] = useState('')
  const [image, setImage] = useState<File | null>(null);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);


  type badType =  null | undefined;

  const handleFileUpload = (file: File) => {
    if (!uploadedImages.some(existingFile => existingFile.name === file.name)) {
      setUploadedImages(prevImages => {
        const updatedImages = [...prevImages, file];
        console.log(updatedImages);
        return updatedImages;
      });
    }
  };


  const handleRemoveFile = (file: File) => {
    setUploadedImages(prevImages => {
      const updatedImages = prevImages.filter(existingFile => existingFile.name !== file.name);
      return updatedImages;
    });
  };

  const manusearImagem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImage(file);
      setErrorImage(false)

    } else if (file == null) {
      setErrorImage(true)
    }

  };

  async function getCategories() {
    try {
      const productDocRef = doc(FBFirestore, "categorias", "produtos");
      const productDocSnap = await getDoc(productDocRef);
      const serviceDocRef = doc(FBFirestore, "categorias", "serviços");
      const serviceDocSnap = await getDoc(serviceDocRef);

      let combinedCategories: any = [];

      if (productDocSnap.exists()) {
        const productCategories = productDocSnap.data().produtos;
        if (productCategories) {

          // Adiciona o prefixo "Produtos:" antes de cada categoria
          combinedCategories = combinedCategories.concat(productCategories.map((category: any) => `Produtos:${category}`));
        }
      } else {
        console.log("Nenhuma Categoria de produto catalogada em banco!");
      }

      if (serviceDocSnap.exists()) {
        const serviceCategories = serviceDocSnap.data().serviços;
        if (serviceCategories) {

          // Adiciona o prefixo "Serviços:" antes de cada categoria
          combinedCategories = combinedCategories.concat(serviceCategories.map((category: any) => `Serviços:${category}`));
        }
      } else {
        console.log("Nenhuma Categoria de Serviço catalogada em banco!");
      }

      setCategories(combinedCategories);
    } catch (e) {
      alert("Erro ao acessar dados no Firestore: " + e);
    }
  }

  React.useEffect(() => {
    getCategories()
  }, [])

  function clearForm() {
    setNome('');
    setDescricao('');
    setWhatsapp(user?.telefone)
    setTelefone(user?.telefone)
    setEndereco('')
    setProductCategories('')
    setServiceCategories('')
    setCategorieSelected('')
    setTipo('')
    setTags([]);
    setImage(null);
    
  }

  async function createItem(data: any) {
    await createServico(data)
    clearForm()
  }

  const validateData = async (data: any): Promise<boolean>  => {
    let returnBool = true
    setWarning("Verifique se todos os campos foram corretamente preenchidos")
    if (data.nome === ""  ){
       setModalAberto(true)
       setErrorName(true)
       returnBool =  false
     } else {  
     setErrorName(false)
    }
    if ( data.descricao === "" ){
      setModalAberto(true)
      setErrorDescricao(true)
      returnBool = false
    } else {
      setErrorDescricao(false)
    } 
    if ((data.whatsapp === "" || data.whatsapp === undefined)   && (data.telefone === "" || data.telefone === undefined) ){
      setModalAberto(true)
      setErrorWhataspp(true)
      setErrorTelefone(true)
      returnBool = false
    } else {
      setErrorWhataspp(false)
      setErrorTelefone(false)
    }
    if (data.endereco === ""){
      setModalAberto(true)
      setErrorEndereco(true)
      returnBool = false
    } else {
      setErrorEndereco(false)
    }
    if (data.image == null){
      setModalAberto(true)
      setErrorImage(true)
      returnBool = false
    } else {
      
      setErrorImage(false)
    }
    if (data.categories === ""){
      setModalAberto(true)
      setErrorCategorias(true)
      
      returnBool = false
    } else {
      setErrorCategorias(false)

    }
    if (data.tags.length === 0){
      setModalAberto(true)
      setErrorTag(true)
      returnBool = false
    } else {
      setErrorTag(false)
    }
   

    return   returnBool  
  }

  function isBadType(value: any): value is badType {
    return (
        value === null ||
        value === undefined
    );
}

  const closeModal = (e: boolean) => {
    setModalAberto(false);
  }

  const handleTipo = async () => {
    let categoriaSelecionada =  categorieSelected.split(":")[0]
 
      setTipo(categoriaSelecionada); // Retorna a Promessa resultante
     
  }


  const handleSubmit = async (e: any) => {
    e.preventDefault();
        
    

    
    if (image) {
      const imageRef = ref(storage, 'images/' + image.name);

      try {
        await uploadBytes(imageRef, image);

        const imageUrl = await getDownloadURL(imageRef);
        // let categoriaSelecionada = await categorieSelected.split(":")[0]
        // await setTipo(categoriaSelecionada)
        await handleTipo()
            let categoriaSelecionada =  categorieSelected.split(":")[0]

        const data = {
          nome,
          descricao,
          whatsapp,
          telefone,
          endereco,
          categories: categorieSelected,
          image: imageUrl,
          imagemExtra: uploadedImages,
          tags: tags,
          tipo: categoriaSelecionada
        };
        let result = await validateData(data)
        if (result) {
           
          await  createItem(data);
      }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
    else {
      setWarning("Verifique se todos os campos foram corretamente preenchidos")
      setModalAberto(true)


      if (nome === "" ){
        setModalAberto(true)
        setErrorName(true)
         
      } else {  
      setErrorName(false)
     }
     if (descricao === ""){
       setModalAberto(true)
       setErrorDescricao(true)
       
     } else {
       setErrorDescricao(false)
     } 
     if ((whatsapp === "" || whatsapp === undefined)   && (telefone === "" || telefone === undefined) ){
       setModalAberto(true)
       setErrorWhataspp(true)
       setErrorTelefone(true)
      } else {
       setErrorWhataspp(false)
       setErrorTelefone(false)
     }
     if ( endereco === ""){
       setModalAberto(true)
       setErrorEndereco(true)
      } else {
       setErrorEndereco(false)
     }
     if (image == null){
       setModalAberto(true)
       setErrorImage(true)
      } else {
       
       setErrorImage(false)
     }
     if (categorieSelected === ""){
       setModalAberto(true)
       setErrorCategorias(true)
       
      } else {
       setErrorCategorias(false)
 
     }
     if (tags?.length == 0  ){
       setModalAberto(true)
       setErrorTag(true)
      } else {
       setErrorTag(false)
     }
    }
   
  }

  const handleNomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNome(value);
 
    if (value.trim() === '') {
      setErrorName(true);
    } else {
      setErrorName(false);
    }
  };

  const handleEnderecoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEndereco(value);
 
    if (value.trim() === '') {
      setErrorEndereco(true);
    } else {
      setErrorEndereco(false);
    }
  };

  const handleWhatsappChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setWhatsapp(value);
 
    if (value.trim() === '') {
      setErrorWhataspp(true);
    } else {
      setErrorWhataspp(false);
     setErrorTelefone(false)
    }
  };
  const handleTelefoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTelefone(value);
 
    if (value.trim() === '') {
      setErrorTelefone(true);
    } else {
      setErrorWhataspp(false);
      setErrorTelefone(false)
    }
  };

  const handleCategorieChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setCategorieSelected(value);
 
    if (value.trim() === '') {
      setErrorCategorias(true);
    } else {
      setErrorCategorias(false);
    }
  };

 
  const handleDescricaoChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setDescricao(value);
 
    if (value.trim() === '') {
      setErrorDescricao(true);
    } else {
      setErrorDescricao(false);
    }
  };

  // Ainda não funcionando
  // const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   const tagsArray = value.split(',');
  
  //   setTags(tagsArray);
  
  //   // Adicione a validação aqui
  //   if (tagsArray.some(tag => tag.trim() === '')) {
  //     setErrorTag(true);
  //   } else {
  //     setErrorTag(false);
  //   }
  // };


  return (
    
    <form className={styles.produto} onSubmit={handleSubmit}>
            {modalAberto && <ModalMessage text={warning} close={closeModal} />}

      <label htmlFor="nome">Nome</label>
      <input className={`${styles.input} ${errorName ? styles.redBorder : styles.noBorder}`} value={nome}        onChange={handleNomeChange} id='nome' type='text' />
      <p className={`${styles.input__error } ${errorName ? styles.display : styles.noDisplay}` } >Campo Obrigártio</p>

      <label htmlFor="descricao">Descrição</label>
      <textarea className={`  ${errorEndereco ? styles.redBorder : styles.noBorder}`} value={descricao} onChange={handleDescricaoChange} id='descricao' rows={10} />
      <p className={`${styles.input__error} ${errorDescricao ?  styles.display : styles.noDisplay}`} >Campo Obrigártio</p>

      <label htmlFor="imagem">Imagem de Capa</label>
      <input className={`${styles.input} ${errorImage ? styles.redBorder : styles.noBorder}`} type="file" accept="image/*" onChange={manusearImagem} />
      <p className={`${styles.input__error } ${errorImage ? styles.display : styles.noDisplay}` } >Campo Obrigártio</p>

      <label htmlFor="imagem">Imagens Extras</label>
      <div className={styles.multiFileUploader}>
        <MultiFileUploader onFileUpload={handleFileUpload} onFileRemove={handleRemoveFile} />
      </div>

      <label htmlFor="whatsapp">Whatsapp</label>
      <input className={`${styles.input} ${errorWhatsapp ? styles.redBorder : styles.noBorder}`} value={whatsapp} onChange={handleWhatsappChange} id='whatsapp' type='tel' />
      <p className={`${styles.input__error } ${errorWhatsapp ? styles.display : styles.noDisplay}` } >Digite um Telefone ou Um Whatsapp</p>

      <label htmlFor="telefone">Telefone</label>
      <input className={`${styles.input} ${errorTelefone ? styles.redBorder : styles.noBorder}`} value={telefone} onChange={handleTelefoneChange} id='telefone' type='tel' />
      <p className={`${styles.input__error } ${errorTelefone ? styles.display : styles.noDisplay}` } >Digite um Telefone ou Um Whatsapp</p>

      <label htmlFor="Endereço">Endereço</label>
      <input className={`${styles.input} ${errorEndereco ? styles.redBorder : styles.noBorder}`} value={endereco} onChange={handleEnderecoChange} id='endereco' type='text' />
      <p className={`${styles.input__error } ${errorEndereco ? styles.display : styles.noDisplay}` } >Campo Obrigártio</p>

      <label htmlFor="categorias">Categorias </label>
      <select className={` ${styles.input} ${errorCategorias ? styles.redBorder : styles.noBorder}`} name="categorias" id="categorias" value={categorieSelected} onChange={handleCategorieChange} >
      <option value={""}>Selecione...</option>
        <optgroup label="Produtos">
          {categories?.map((item: string) => {
            if (item.includes("Produtos:")) { 
              return <option key={item} value={item}>{item.replace("Produtos:", "")}</option>;
            }
            return null;
          })}
          <option key={"ProdutoNãoEncontrado"} value={"NãoEncontrado"}>Minha Categoria Não Está na Lista</option>

        </optgroup>
        <optgroup label="Serviços"  >
          {categories?.map((item: string) => {
            // Aqui você pode verificar se a categoria pertence a "Serviços" e, em seguida, criar a opção
            if (item.includes("Serviços:")) {
              return <option key={item} value={item} >{item.replace("Serviços:", "")}</option>;
            }
            return null;
          })}
          <option key={"ServiçoNãoEncontrado"} value={"NãoEncontrado"}>Minha Categoria Não Está na Lista</option>
        </optgroup>
      </select>
      <p className={`${styles.input__error } ${errorCategorias ? styles.display : styles.noDisplay}` } >Campo Obrigártio</p>

      <label htmlFor="categorias">Palavras Chaves</label>
      <TagContainer  tags={tags} setTags={setTags} errorBool={errorTag} />
      <p className={`${styles.input__error } ${errorTag ? styles.display : styles.noDisplay}` } >Campo Obrigártio</p>

      <BtnPadrao type="submit">Salvar</BtnPadrao>

    </form>
  )
}

export default FormCadastro