import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";  

import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from "firebase/auth";
 
function firebaseConfig() {
    return {
        apiKey: process.env.REACT_APP_apiKey,
        authDomain: process.env.REACT_APP_authDomain,
        projectId: process.env.REACT_APP_projectId,
        databaseURL: process.env.REACT_APP_databaseURL,
        storageBucket: process.env.REACT_APP_storageBucket,
        messagingSenderId: process.env.REACT_APP_messagingSenderId,
        appId: process.env.REACT_APP_appId

    };
}
const app = initializeApp(firebaseConfig())

export const FBFirestore = getFirestore(app);

export const FBAuth = getAuth(app);
export const storage = getStorage(app);

export const googleProvider = new GoogleAuthProvider()

export const facebookProvider = new FacebookAuthProvider();

export const twitterProvider = new TwitterAuthProvider();