import styles from "./CardValidacao.module.scss";
import IProdutoServico from "interfaces/IProdutoServico";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface ICArdValidacaoProps extends IProdutoServico {
  aprovarCard: (cardId: string) => void;
  reprovarCard: (cardId: string) => void;
  recarregar: number;
  setRecarregar: (e: number) => void;
}

function CardValidacao({
  categoria,
  aprovado,
  descricao,
  id,
  img,
  numeroComercial,
  whatsapp,
  titulo,
  aprovarCard,
  reprovarCard,
  recarregar,
  setRecarregar,
}: ICArdValidacaoProps) {
  const aprovar = (id: string) => {
    aprovarCard(id);
    setRecarregar(recarregar + 1);
  };
  const reprovar = (id: string) => {
    reprovarCard(id);
    setRecarregar(recarregar + 2);
  };
  const ir = useNavigate();
  const primeiros250Caracteres = descricao.slice(0, 250);
  const estiloDoFundo = {
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover'
  }

  return (
    <section key={id} className={styles.card}>
      <span className={styles.card__categoria}>{categoria}</span>
      <header className={styles.card__titulo}>{titulo}</header>
      <p className={styles.card__descricao}>{primeiros250Caracteres+"..."}</p>      
      <div className={styles.card__img} style={estiloDoFundo} />
      <nav>
  
        <button onClick={() => reprovar(id)}>
          <AiOutlineClose />
        </button>
        <button onClick={() => aprovar(id)}>
          <AiOutlineCheck />
        </button>
        <button onClick={() => ir(`/maisInfo/${id}`) }>
          <FiPlus /> Ler Mais ...
        </button> 
      </nav>
    </section>
  );
}

export default CardValidacao;
