import styles from './MultiFileUploader.module.scss'

import React, { useState, Fragment } from 'react';

 
import { Card, CardHeader, CardTitle, CardBody, Button, ListGroup, ListGroupItem } from 'reactstrap'
 
import { useDropzone } from 'react-dropzone'
import { FileText, X, DownloadCloud } from 'react-feather'


interface MultiFileUploaderProps {
  onFileUpload: (file: File) => void;
  
  onFileRemove?: (file: File) => void;
}

function MultiFileUploader({ onFileUpload, onFileRemove  }: MultiFileUploaderProps)  {
 
   
  const [files, setFiles] = useState<File[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => { 
      if (files.length + acceptedFiles.length <= 3) {
       setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      acceptedFiles.forEach(file => onFileUpload(file));
    } else {
       alert('Número máximo de imagens extra é 3.');
    }
    }
  });

  const renderFilePreview = (file: File) => {
    if (file.type.startsWith('image')) {
      return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28' />
    } else {
      return <FileText size='28' />
    }
  }

 
  const handleRemoveFile = (file: File) => {
    setFiles((prevFiles) => prevFiles.filter(f => f.name !== file.name));
    if (onFileRemove) {
      onFileRemove(file);
    }
  };
  
  
  
 

  const fileList = files.map((file, index) => (
    <ListGroupItem key={`${file.name}-${index}`} className={styles.files}>
      <div className={styles.filedetailbody}>
        <div className='file-preview me-1'>{renderFilePreview(file)}</div>
        <div>
          <p className='file-name mb-0'>{file.name}</p> 
        </div>
      </div>
      <Button color='danger' outline size='sm' className={styles.removeImageButton} onClick={() => handleRemoveFile(file)}>
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))

  const handleRemoveAllFiles = () => {
    setFiles([])
  }

  return (
    <Card className='card'>
 
      <CardBody className={styles.dropzone}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div 
          className={styles.droparea}
          >
            <DownloadCloud size={64} />
            <h5>Clique aqui para adicionar imagens extras</h5>
     
          </div>
        </div>
        {files.length ? (
          <Fragment>
            <ListGroup className={styles.filelist} >{fileList}</ListGroup>
            <div >
              {/* <Button  className={styles.removeButton} color='danger' outline onClick={handleRemoveAllFiles}>
                Remover Todas Imagens
              </Button>  */}
            </div>
          </Fragment>
        ) : null}
      </CardBody>
    </Card>
  )
}
  export default MultiFileUploader