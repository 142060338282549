import { getDocs, collection, where, query, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import React from 'react';
import { FBFirestore } from 'services/firebaseApiKey';

export default function useGetCardsNaoAprovados() {
    const [cards, setCards] = React.useState<any[]>([]);
    const [users, setUsers] = React.useState<any[]>([]);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const getUsersNaoAprovados = React.useCallback(async () => {
        try {
            setError('');
            setLoading(true);

            // Crie uma consulta que filtra documentos em que "aprovado" é "false"
            const q = query(collection(FBFirestore, "usuarios"), where("prestadorAprovado", "==", false),where("prestador", "==", true));
            const querySnapshot = await getDocs(q);

            const newUsers: any = [];

            querySnapshot.forEach((doc) => {
                const newData = doc.data();
                newData.id = doc.id;

                const isDuplicate = newUsers.some((user: any) => user.titulo === newData.titulo);
                if (!isDuplicate) {
                    newUsers.push(newData);
                }
            });

            setUsers(newUsers);
        } catch (e) {
            setError("Erro ao acessar dados no Firestore: " + e);
        } finally {
            setLoading(false);
        }
    }, []);

    const getCardsNaoAprovados = React.useCallback(async () => {
        try {
            setError('');
            setLoading(true);

            // Crie uma consulta que filtra documentos em que "aprovado" é "false"
            const q = query(collection(FBFirestore, "anuncios"), where("aprovado", "==", false));
            const querySnapshot = await getDocs(q);

            const newCards: any = [];

            querySnapshot.forEach((doc) => {
                const newData = doc.data();
                newData.id = doc.id;

                const isDuplicate = newCards.some((card: any) => card.titulo === newData.titulo);
                if (!isDuplicate) {
                    newCards.push(newData);
                }
            });

            setCards(newCards);
        } catch (e) {
            setError("Erro ao acessar dados no Firestore: " + e);
        } finally {
            setLoading(false);
        }
    }, []);

    const refreshData = () => {
        getCardsNaoAprovados()
        getUsersNaoAprovados()
    }

    const aprovarCard = async (cardId: string) => {
        try {
            const cardRef = doc(FBFirestore, "anuncios", cardId);

            // Atualize o valor "aprovado" para true
            await updateDoc(cardRef, {
                aprovado: true,
            });
        } catch (e) {
            setError("Erro ao aprovar o card: " + e);
        }
    };

    const aprovarUser = async (cardId: string) => {
        try {
            const cardRef = doc(FBFirestore, "usuarios", cardId);

            // Atualize o valor "aprovado" para true
            await updateDoc(cardRef, {
                prestadorAprovado: true,
            });
        } catch (e) {
            setError("Erro ao aprovar o card: " + e);
        }
    };


    const reprovarCard = async (cardId: string) => {
        try {
            // Adicione o mesmo documento à coleção "anunciosReprovados" com "reprovado" igual a true
            const reprovadoCollectionRef = collection(FBFirestore, "anunciosReprovados");
            const newReprovadoDocRef = await addDoc(reprovadoCollectionRef, {
                ...cards.find((card) => card.id === cardId),
                reprovado: true,
            });

            const cardRef = doc(FBFirestore, "anuncios", cardId);

            // Delete o documento da coleção "anuncios"
            await deleteDoc(cardRef);

            console.log("Documento reprovado movido para anunciosReprovados:", newReprovadoDocRef.id);
        } catch (e) {
            setError("Erro ao reprovar o card: " + e);
        }
    }
    const reprovarUser = async (userId: string) => {
        try {
            // Adicione o mesmo documento à coleção "anunciosReprovados" com "reprovado" igual a true
            const reprovadoCollectionRef = collection(FBFirestore, "userReprovados");
            const newReprovadoDocRef = await addDoc(reprovadoCollectionRef, {
                ...users.find((user) => user.id === userId),
                reprovado: true,
            });

            const cardRef = doc(FBFirestore, "usuarios", userId);

            // Delete o documento da coleção "anuncios"
            await deleteDoc(cardRef);

            console.log("Documento reprovado movido para anunciosReprovados:", newReprovadoDocRef.id);
        } catch (e) {
            setError("Erro ao reprovar o card: " + e);
        }
    }


    return {
        cards,
        users,
        getUsersNaoAprovados,
        loading,
        aprovarCard,
        error,
        getCardsNaoAprovados,
        reprovarCard,
        aprovarUser,
        reprovarUser,
        refreshData
    };
}
