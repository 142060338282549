import React, { useState } from 'react';
import styles from './TagContainer.module.scss';

interface TagContainerProps{
    setTags: ([]) => void,
    tags: string[],
    errorBool: boolean

}
function TagContainer({setTags,tags, errorBool}:TagContainerProps) {
    

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key !== 'Enter') return;
        e.preventDefault();
        const value = (e.target as HTMLInputElement).value;
        if (!value.trim()) return;
        setTags([...tags, value]);
        (e.target as HTMLInputElement).value = '';
    }

    function removeTag(index: number) {
        setTags(tags.filter((el, i) => i !== index));
    }

    return (

        <div className={`${styles['tags-input-container']} ${errorBool ? styles.redBorder : styles.noBorder }`}>
            {tags.map((tag, index) => (
                <div className={styles['tag-item']} key={index}>
                    <span className="text">{tag}</span>
                    <span className={styles['close']} onClick={() => removeTag(index)}>
                        &times;
                    </span>
                </div>
            ))}
            <input
                onKeyDown={handleKeyDown}
                type="text"
                className={styles['tags-input']}
                placeholder="Digite uma palavra chave"
            />
        </div>

    );
}

export default TagContainer;