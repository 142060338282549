import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import "../../styles/forms/form.scss";
import { useForm } from "react-hook-form";
import { Button, Form, Container, Card, CardBody, CardTitle } from "react-bootstrap";
import InputMask from "react-input-mask";
import { UserContext } from "contexts/UserContext";
import { FBAuth, FBFirestore } from "services/firebaseApiKey";
import { DocumentSnapshot, doc, getDoc, updateDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import IUser from "interfaces/IUser";

type Inputs = {
  nome: string;
  email: string;
  telefone: string;
  whatsapp: boolean;
  prestador: boolean;
};

function PerfilEditar() {
  const { user, setUser } = React.useContext(UserContext);
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      nome: user?.displayName,
      email: user?.email,
      telefone: user?.telefone,
      whatsapp: user?.whatsapp,
      prestador: user?.prestador,
    },
  });

  async function editUser(data: Inputs) {
    const firebaseUser = FBAuth.currentUser;

    if (firebaseUser) {
      const userDocRef = doc(FBFirestore, "usuarios", firebaseUser.uid);

      try {
        updateDoc(userDocRef, {
          displayName: data.nome,
          telefone: data.telefone,
          whatsapp: data.whatsapp,
          prestador: data.prestador,
        });
        updateProfile(firebaseUser, {
          displayName: data.nome,
        });
        const userDocSnapshot = await getDoc(userDocRef) as DocumentSnapshot<IUser>
        setUser(userDocSnapshot.data())
        sessionStorage.setItem("userLogado", JSON.stringify(userDocSnapshot.data()));
        console.log(userDocSnapshot.data())
        navigate('../perfil')
      } catch (err) {
        alert("Erro ao atualizar usuario" + err);
      }
    }
  }

  const handleAuthentication = (data: Inputs) => {
    editUser(data);
  };

  return (
    <Card className="formLogin">
      <Link to={"/home/perfil"}>
        <FaChevronLeft className="backButton" />
      </Link>

      <CardBody>
        <Form onSubmit={handleSubmit(handleAuthentication)}>
          <Form.Group controlId="formBasicName">
            <Form.Label>Nome Completo</Form.Label>
            <Form.Control type="text" {...register("nome", { required: true })} className={errors.nome ? "is-invalid" : ""} />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control type="email" {...register("email", { required: true })} className={errors.email ? "is-invalid" : ""} disabled />
          </Form.Group>

          <Form.Group controlId="formBasicPhone">
            <Form.Label>Telefone Celular</Form.Label>
            {/* <InputMask
              mask="(99) 99999-9999"
              maskChar="_"
                            type="tel"
              {...register("telefone", {
                required: true,
                validate: (value) => /\d/g.test(value) || "Telefone deve conter apenas números",
              })}
              className={errors.telefone ? "is-invalid" : ""}
            /> */}
            <Form.Control
              type="tel"
              {...register("telefone", {
                required: true,
                validate: (value) => /\d/g.test(value) || "Telefone deve conter apenas números",
              })}
              className={errors.telefone ? "is-invalid" : ""}
            />
          </Form.Group>

          <Form.Group controlId="formBasicWhatsApp">
            <Form.Check type="checkbox" label="Este número tem WhatsApp" {...register("whatsapp")} />
          </Form.Group>
          <Form.Group controlId="formBasicPrestador">
            <Form.Check type="checkbox" label="Sou prestador de serviços" {...register("prestador")} disabled={user?.prestador } />
          </Form.Group>

          <Container id="submitButton">
            <Button type="submit"> Salvar</Button>
          </Container>
        </Form>
      </CardBody>
    </Card>
  );
}

export default PerfilEditar;
