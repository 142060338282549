import React from "react";
import styles from './PaginaDeLoader.module.scss';

function PaginaDeLoader() {
  return (
    <section className={styles.container}>
      <div className={styles.loaderContainer}>
        <span className={styles.loader}>
          <p>Carregando</p>
        </span>
      </div>
    </section>
  );
}

export default PaginaDeLoader;
