import React from 'react'
import styles from './Teste.module.scss';
import PaginaDeLoader from 'component/PaginaDeLoader';






function Teste() {
  

  
  return (
    <div>
      <PaginaDeLoader />
    </div>
  )
}

export default Teste