import styles from "./CardHome.module.scss";
import IProdutoServico from "interfaces/IProdutoServico";
import { FiPlus } from "react-icons/fi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BiPhoneCall } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function Card({
  categoria,
  aprovado,
  descricao,
  id,
  img,
  numeroComercial,
  whatsapp,
  titulo,
  confradeNome
}: IProdutoServico) {
  const handleCall = () => {
    window.location.href = `tel:${numeroComercial}`;
  };
  const estiloDoFundo = {
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover'
  }

  const handleWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?phone=${whatsapp}`, "_blank");
  };
  const ir = useNavigate();

  const primeiros250Caracteres = descricao.slice(0, 250);

  return (
    <section key={id} className={aprovado ? styles.card : styles.notApproved}>
      <span className={styles.card__categoria}>{categoria}</span>
      <div className={styles.card__tituloeconfrade}>

      <header className={styles.card__titulo}>{titulo}</header>
      </div>
      <span className={styles.card__confrade}>{confradeNome}</span>
      <p className={styles.card__descricao}>{primeiros250Caracteres+"..."}</p>      
      <div className={styles.card__img} style={estiloDoFundo} />
      <nav>
        <button onClick={() => ir(`/maisInfo/${id}`)}>
          <FiPlus /> Ler Mais ...
        </button>
        <button onClick={handleWhatsApp}>
          <AiOutlineWhatsApp />
        </button>
        <button onClick={handleCall}>
          <BiPhoneCall />
        </button>
      </nav>
    </section>
  );
}

export default Card;
