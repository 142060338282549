import IProdutoServico from "interfaces/IProdutoServico";
import React from "react";

interface CardSearchContextProps {
  children: React.ReactNode;
}
interface CardSearchContextType {
  filter: string;
  setFilter: (newState: string) => void;
  order: string;
  setOrder: (newState: string) => void;
  search: string;
  setSearch: (newState: string) => void;
  confrade: string;
  setConfrade: (newState: string) => void;
  applyCardFiltering: (card: any[]) => any[];
  clearFiltering: ()=> void
}

const initialValue = {
  filter: "",
  setFilter: () => {},
  order: "",
  setOrder: () => {},
  search: "",
  setSearch: () => {},
  confrade: "",
  setConfrade: () => {},
  applyCardFiltering: () => {
    return [];
  },
  clearFiltering:()=>{}
};

export const CardSearchContext = React.createContext<CardSearchContextType>(initialValue);

export const CardSearchContextProvider = ({ children }: CardSearchContextProps) => {
  const [filter, setFilter] = React.useState("");
  const [order, setOrder] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [confrade, setConfrade] = React.useState("");

  function removeSpecialCharacters(inputString: string) {
    // Normalize accented characters to their non-accented equivalents

    const normalizedString = inputString?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // Remove any remaining non-alphanumeric characters
    const sanitizedString = normalizedString?.replace(/[^a-zA-Z0-9\s]/g, "");

    return sanitizedString;
  }

  function applyCardFiltering(cards: IProdutoServico[]): any[] {
    let sortedData = [...cards]; // Create a new array for sorting

    switch (order) {
      case "az":
        sortedData = sortedData.sort((a, b) => a.titulo?.localeCompare(b.titulo));
        break;
      case "za":
        sortedData = sortedData.sort((a, b) => b.titulo?.localeCompare(a.titulo));
        break;
      default:
        break;
    }

    // Apply filtering if 'filter' is set
    if (filter) {
      sortedData = sortedData.filter((card) => card.tipo?.toLowerCase().includes(filter) );
    }

    if (search) {
      const finalSearch = removeSpecialCharacters(search);
      sortedData = sortedData.filter(
        (card) =>
          removeSpecialCharacters(card.titulo?.toLowerCase())?.includes(finalSearch.toLowerCase()) ||
          removeSpecialCharacters(card.categoria?.toLowerCase())?.includes(finalSearch.toLowerCase()) ||
          removeSpecialCharacters(card.confradeNome?.toLowerCase())?.includes(finalSearch.toLowerCase()) ||
          (card.palavrasChave?.some(keyword =>
            removeSpecialCharacters(keyword?.toLowerCase())?.includes(finalSearch.toLowerCase())
          ) ?? false)
      );
    }

    if (confrade) {
      sortedData = sortedData.filter((card) => card.confradeNome === confrade);
    }

    sortedData = sortedData.filter((card)=> card.aprovado)
    // Update the 'filteredCards' state with the sorted and filtered data
    return sortedData;
  }

  function clearFiltering() {
    setFilter('')
    setSearch('')
    setConfrade('')
  }

  return (
    <CardSearchContext.Provider value={{ filter, setFilter, order, setOrder, search, setSearch, confrade, setConfrade, applyCardFiltering, clearFiltering }}>
      {children}
    </CardSearchContext.Provider>
  );
};
