import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "./styles/styles.scss";
import Login from "./pages/LoginPage/Login";
import LoginEntrar from "./pages/LoginPage/LoginEntrar";
import LoginCadastro from "./pages/LoginPage/LoginCadastro";
import LoginForgotPassword from "./pages/LoginPage/LoginForgotPassword";
import PaginaComum from "./pages/PaginaComum";
import Home from "./pages/Home";
import Cadastro from "./pages/Cadastro";
import PaginaDeErro from "./pages/PaginaDeErro";
import Teste from "./pages/Teste";
import Validacao from "pages/Validacao/Index";
import Perfil from "pages/Perfil";
import { UserContextProvider } from "contexts/UserContext";
import MaisInfo from "pages/MaisInfo";
import PerfilEditar from "pages/PerfilEditar";
import { CardSearchContextProvider } from "contexts/CardSearchContext";

function RoutesAPP() {
  return (
    <Suspense fallback={<div>Carregando</div>}>
      <UserContextProvider>
      <CardSearchContextProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/entrar" element={<LoginEntrar />} />
          <Route path="/esqueciminhasenha" element={<LoginForgotPassword />} />
          <Route path="/registrarse" element={<LoginCadastro />} />
          <Route path="/teste" element={<Teste />} />
          <Route path="/home" element={<PaginaComum />}>
            <Route index element={<Home />} />
            <Route path="cadastro" element={<Cadastro />} />
            <Route path="validacao" element={<Validacao />} />
            <Route path="perfil" element={<Perfil/>} />
            <Route path="perfil/editar" element={<PerfilEditar/>} />
          </Route>
          <Route path="*" element={<PaginaDeErro />} />
          <Route path="/maisInfo/:id" element={<MaisInfo />} />
        </Routes>
      </CardSearchContextProvider>
      </UserContextProvider>
    </Suspense>
  );
}

export default RoutesAPP;
