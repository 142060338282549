import React from "react";
import styles from "./ModalCadastro.module.scss";

interface IModalCadastroProps {
  aberto: boolean;
  sair: (e: boolean) => void;
}

function ModalCadastro({ aberto, sair }: IModalCadastroProps) {
  const sim = () => {    
    sair(true);
  };
  const nao = () => {    
    sair(false);
  };

  return (
    <div
      className={`${styles.modalCadastro} ${
        aberto ? styles.aberto : styles.fechado
      }`}
    >
      <section className={styles.modalCadastro__modal}>
        <div className={styles.modalCadastro__modal__body}>
          <p>
            Seu cadastro não foi concluido, gostaria mesmo de sair da pagina?
          </p>
        </div>
        <div className={styles.modalCadastro__modal__btns}>
          <button onClick={sim}>Sim</button>
          <button onClick={nao}>Nao</button>
        </div>
      </section>
    </div>
  );
}

export default ModalCadastro;
