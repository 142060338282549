import React from "react";
import styles from "./Filtro.module.scss";
import { HiOutlineFunnel } from "react-icons/hi2";

type Cards = "serviço" |'produto' | ''

interface FiltroProps {
  onFilterChange: (filter: Cards) => void
  searchFocused: boolean
}

function Filtro({ onFilterChange, searchFocused }: FiltroProps) {
  const [dropdownActive, setDropdownActive] = React.useState(false)
  const filtroRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (filtroRef.current && !filtroRef.current.contains(event.target as Node)) {
        // Clicked outside of the component, so close the dropdown
        setDropdownActive(false);
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleFilterChange(filter: Cards) {
    onFilterChange(filter)
  }
  return (
    <nav className={`${styles.filtro} ${searchFocused && styles.filtroHidden}`} onClick={()=>setDropdownActive(!dropdownActive)} ref={filtroRef}>
      <div className={styles.filtro__item} onClick={()=>setDropdownActive(!dropdownActive)}>
      <HiOutlineFunnel/>
        <div className={`${styles.filtro__item__dropdown} ${dropdownActive&& styles.filtro__item__dropdown__active}`}>
          <div>
            <button onClick={() =>handleFilterChange("serviço")}>Serviços</button>
            <button onClick={()=>handleFilterChange("produto")}>Produtos</button>            
            <button onClick={()=>handleFilterChange("")}>Todos</button>            
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Filtro;
