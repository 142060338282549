import React, { useState } from "react";
import styles from "./Home.module.scss";
import CardHome from "./CardHome";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { GrLogout } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import Ordenador from "./Ordenador";
import Filtro from "./Filtro";
import PaginaDeLoader from "component/PaginaDeLoader";
import { UserContext } from "contexts/UserContext";
import useGetCards from "hooks/useGetCards";
import SemCartas from "pages/SemCartas";
import { CardSearchContext } from "contexts/CardSearchContext";

function Home() {
  const [filteredCards, setFilteredCards] = useState<any[]>([]);
  const [lupaVisivel, setLupaVisivel] = useState(false);
  const { userLogout } = React.useContext(UserContext);

  const{order,filter,confrade,search, setSearch, setOrder, setFilter, setConfrade, applyCardFiltering, clearFiltering}= React.useContext(CardSearchContext)

  const { cards, getCards, loading,} = useGetCards();

  React.useEffect(() => {
    getCards();
  }, [getCards]);
  

  React.useEffect(() => {
    setFilteredCards(applyCardFiltering(cards))
  }, [order, cards, confrade, filter, search, applyCardFiltering]);

  const searchInput = React.useRef<HTMLInputElement>(null);

  // Handle search input changes
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchText = event.target.value.toLowerCase();
    setSearch(searchText);
  };

  function setFocus() {
    setLupaVisivel(true);
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }

  if (loading) return <PaginaDeLoader />;
  else
    return (
      <section className={styles.home}>
        <section className={styles.home__header}>
          <div className={styles.home__pesquisa}>
            <HiOutlineMagnifyingGlass
              className={`${styles.home__pesquisa__BtnPesquisa} ${
                lupaVisivel
                  ? styles.home__pesquisa__BtnPesquisaHidden
                  : styles.home__pesquisa__BtnPesquisa
              }`}
              onClick={setFocus}
            />
            <input
              ref={searchInput}
              type="text"
              onClick={setFocus}
              placeholder="Pesquise aqui"
              onBlur={() => setLupaVisivel(false)}
              onChange={handleSearchInputChange}
            />
          </div>
          <Ordenador onOrderByChange={setOrder} searchFocused={lupaVisivel} />
          <Filtro onFilterChange={setFilter} searchFocused={lupaVisivel} />
          <button className={`${styles.home__header__logoff} ${lupaVisivel && styles.home__header__logoffHidden}`} onClick={userLogout}>
            <GrLogout />
          </button>
        </section>
        {(filter || search || confrade)&& <section className={styles.home__filtros}>
          <h1 className={styles.home__filtros__titulo}>Buscas</h1>
          <div className={styles.home__filtros__container}>
            {filter&&<h3 className={styles.home__filtros__container__filtro}>{ filter} <AiOutlineClose onClick={()=>setFilter('')}/></h3>}
            {search && <h3 className={styles.home__filtros__container__filtro}>{search} <AiOutlineClose onClick={() => {
              searchInput.current!.value = ''
              setSearch('')
            }} /></h3>}
            {confrade&&<h3 className={styles.home__filtros__container__filtro}>{ confrade} <AiOutlineClose onClick={()=>setConfrade('')}/></h3>}
          </div>
          <button className={styles.home__filtros__limpar} onClick={()=>
            {searchInput.current!.value = ''
            clearFiltering()}
          }>Limpar Filtros</button>
        </section>}
        <section className={`${styles.home__cards} ${(filter || search || confrade)&& styles.home__cards__filtered}`}>
          {filteredCards.length > 0 ? (
            filteredCards.map((e) => <CardHome key={e.id} {...e} />)
          ) : (
            <SemCartas carta="produto/serviço" />
          )}
        </section>
      </section>
    );
}

export default Home;
