import React from 'react'
import styles from  './BtnPadrao.module.scss';

// interface BtnPadraoProps {
//     children: ReactNode
// }
interface BtnPadraoProps extends React.ComponentPropsWithoutRef<"button"> {
  type: 'submit' | 'button' | 'reset';  
  children: React.ReactNode;  
  className?: string
};

const BtnPadrao: React.FC<BtnPadraoProps> = ({ type, className, children, ...props }) => {
  return (
    <button type={type} className={`${styles.btnPadrao} ${className|| ''}`} {...props}>
        {children}
    </button>
  )
}

export default BtnPadrao