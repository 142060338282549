import React from "react";
import styles from "./ModalMessage.module.scss";

interface IModalMessageProps {
 text: string; 
  close: (e: boolean) => void;
}

function ModalMessage({ text, close  }: IModalMessageProps) {
    const closeModal = () => {    
        close(true);
      };
  return (
    <div
      className={`${styles.modalMessage} ${
      styles.aberto 
      }`}
    >
      <section className={styles.modalMessage__modal}>
        <div className={styles.modalMessage__modal__body}>
          <p>
            {text}
          </p>
        </div>
        <div className={styles.modalMessage__modal__btns}>
          <button onClick={closeModal}>Ok</button> 

        </div>
      </section>
    </div>
  );
}

export default ModalMessage;
