import { createUserWithEmailAndPassword } from "firebase/auth";
import { FBAuth, FBFirestore } from "../firebaseApiKey";
import { setDoc, doc } from "firebase/firestore";
import IAuth from "../../interfaces/IAuth";

export default async function createUserWithEmail({
  nome,
  email,
  senha,
  telefone,
  whatsapp,
  prestador,
}: IAuth) {
  try {

    //cria login do usuario
    await createUserWithEmailAndPassword(FBAuth, email, senha)
      .then(async (userCredential) => {
    
        const uid = userCredential.user.uid
        const userDocRef = await doc(FBFirestore, "usuarios", uid);

        try {
    
              await setDoc(userDocRef, {
              created: 'email',
              displayName: nome,
              email: email, 
              telefone: telefone,
              whatsapp: whatsapp,
              photoURL: '',
              prestador: prestador,
              prestadorAprovado: false,
              admin: false
            });
            alert("Usuário criado com sucesso");
          } catch (e) {
            alert("Erro ao tentar realizar cadastro.");
            console.log(e)
          }
        })
  } catch (e) {
    alert("Erro ao criar usuario");
    console.log(e)
  }
}
