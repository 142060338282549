import React, { useState } from "react";
import styles from "./NavMenu.module.scss";
import { AiFillHome, AiOutlineUser, AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import ModalCadastro from "component/ModalCadastro";
import { UserContext } from "contexts/UserContext";
import useGetCardsNaoAprovados from "hooks/useGetCardsNaoValidados";

function NavMenu() {
  const paginaAtual = useLocation().pathname;
  const [modalAberto, setModalAberto] = useState(false);
  const [tentativa, setTentativa] = useState("");
  const ir = useNavigate();
  const { user } = React.useContext(UserContext);

  const { refreshData, cards, users } = useGetCardsNaoAprovados();

  React.useEffect(() => {
    if (user?.admin) {
      refreshData();
    }
  }, [user ]);

  const aoTrocarDePagina = (novaPagina: string) => {
    setTentativa(novaPagina);
    if (paginaAtual !== "/home/cadastro") {
      ir(novaPagina);
    } else {
      setModalAberto(true);
    }
  };

  const aoSairDoCadastro = (e: boolean) => {
    setModalAberto(false);

    if (e) {
      ir(tentativa); // Troque para a página desejada
    }
  };

  return (
    <nav className={styles.navMenu}>
      <ModalCadastro aberto={modalAberto} sair={aoSairDoCadastro} />
      <div onClick={() => aoTrocarDePagina("/home")} className={`${styles.navMenu__listaItem} ${paginaAtual === "/home" && styles.ativo}`}>
        <AiFillHome />
        <span className={`${styles.navMenu__listaItem__nome} ${paginaAtual === "/home" && styles.ativo__nome}`}>Home</span>
      </div>
      <div
        onClick={() => aoTrocarDePagina("/home/perfil")}
        className={`${styles.navMenu__listaItem} ${paginaAtual === "/home/perfil" && styles.ativo}`}
      >
        <AiOutlineUser />
        <span className={`${styles.navMenu__listaItem__nome} ${paginaAtual === "/home/perfil" && styles.ativo__nome}`}>Perfil</span>
      </div>
      {user?.prestador && user?.prestadorAprovado && (
        <div
          onClick={() => aoTrocarDePagina("/home/cadastro")}
          className={`${styles.navMenu__listaItem} ${paginaAtual === "/home/cadastro" && styles.ativo}`}
        >
          <AiOutlinePlus />
          <span className={`${styles.navMenu__listaItem__nome} ${paginaAtual === "/home/cadastro" && styles.ativo__nome}`}>Criar</span>
        </div>
      )}
      {user?.admin && (
        <div
          onClick={() => aoTrocarDePagina("/home/validacao")}
          className={`${styles.navMenu__listaItem} ${paginaAtual === "/home/validacao" && styles.ativo}
          ${(users.length > 0 || cards.length> 0) && (paginaAtual !== "/home/validacao") && styles.navMenu__listaItem__need__validation}
          `}
        >
          <AiOutlineCheck/>
          <span
            className={`${styles.navMenu__listaItem__nome} ${paginaAtual === "/home/validacao" && styles.ativo__nome}
          `}
          >
            Validar
          </span>
        </div>
      )}
    </nav>
  );
}

export default NavMenu;
