import React from "react";
import styles from "./Perfil.module.scss";
import { UserContext } from "contexts/UserContext";
import { BsFillCheckSquareFill, BsFillXSquareFill, BsFillDashSquareFill } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import BtnPadrao from "component/BtnPadrao";
import { useNavigate } from "react-router-dom";

function Perfil() {
  const { user } = React.useContext(UserContext);
  const ir = useNavigate();

  return (
    <div className={styles.perfil}>
      <div>
        <img src={user?.photoURL} alt="usuario" className={styles.perfil__photo} />
        <h1 className={styles.perfil__name}>{user?.displayName}</h1>
      </div>
      <div>
        <h2 className={styles.perfil__label}>Email</h2>
        <h3 className={styles.perfil__info}>{user?.email}</h3>
      </div>
      <div>
        <h2 className={styles.perfil__label}>Telefone</h2>
        {user?.telefone ? (
          <h3 className={styles.perfil__info}>
            {user.telefone} {user.whatsapp && <IoLogoWhatsapp className={styles.whatsapp} />}
          </h3>
        ) : (
          <h3 className={styles.perfil__info__erro}>Nao tem numero registrado</h3>
        )}
      </div>
      <div>
        <h2>
          {user?.prestador ? (
            user?.prestadorAprovado ? (
              <BsFillCheckSquareFill className={styles.check} />
            ) : (
              <BsFillDashSquareFill className={styles.checking} />
            )
          ) : (
            <BsFillXSquareFill className={styles.notChecked} />
          )}{" "}
          Prestador de serviço
          {user?.prestador && !user.prestadorAprovado && <p className={styles.checking}>aguardando aprovação de admin</p>}
        </h2>
      </div>

      <BtnPadrao type="button" className={styles.perfil__botao} onClick={() => ir("/home/perfil/editar")}>
        Editar
      </BtnPadrao>
    </div>
  );
}

export default Perfil;
