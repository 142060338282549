import React, { useState } from "react";
import styles from "./Validacao.module.scss";
import img from "../../assets/imgs/trabalho-em-progresso.png";
import useGetCardsNaoAprovados from "hooks/useGetCardsNaoValidados";
import CardValidacao from "./CardValidacao";
import CardValidacaoUsuario from "./CardValidacaoUsuario";
import SemCartas from "pages/SemCartas";

function Validacao() {
  const {
    cards,
    error,
    getCardsNaoAprovados,
    loading,
    aprovarCard,
    reprovarCard,
    getUsersNaoAprovados,
    users,
    aprovarUser,
    reprovarUser,
  } = useGetCardsNaoAprovados();

  const [recarregar, setRecarregar] = useState(0);
  const [listaServico, setListaServico] = useState(true);

  React.useEffect(() => {
    getCardsNaoAprovados();
  }, [getCardsNaoAprovados]);

  React.useEffect(() => {
    getUsersNaoAprovados();
  }, [getUsersNaoAprovados]);

  React.useEffect(() => {
    getCardsNaoAprovados();
    getUsersNaoAprovados();
  }, [recarregar]);

  return (
    <section className={styles.validacao}>
      {listaServico ? (
        <h2 className={styles.validacao__titulo}>Serviços</h2>
      ) : (
        <h2 className={styles.validacao__titulo}>Usuarios</h2>
      )}
      <div className={styles.validacao__btns}>
        <button
          className={`${
            listaServico
              ? styles.validacao__btns__ativo
              : styles.validacao__btns__inativo
          }`}
          onClick={() => setListaServico(true)}
        >
          Serviços
        </button>
        <button
          className={`${
            !listaServico
              ? styles.validacao__btns__ativo
              : styles.validacao__btns__inativo
          }`}
          onClick={() => setListaServico(false)}
        >
          Usuarios
        </button>
      </div>
       
      {listaServico
        ? cards.length > 0 ?
          cards.map((e) => (
            <CardValidacao
              reprovarCard={reprovarCard}
              recarregar={recarregar}
              setRecarregar={setRecarregar}
              aprovarCard={aprovarCard}
              {...e}
            />
          )) : <SemCartas carta="serviços"/>
        : users.length > 0 ?
          users.map((e) => (
            <CardValidacaoUsuario
              reprovarCard={reprovarUser}
              recarregar={recarregar}
              setRecarregar={setRecarregar}
              aprovarCard={aprovarUser}
              {...e}
            />
          )): <SemCartas carta="usuarios"/>}
    </section>
  );
}

export default Validacao;
