import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";
import '../../../styles/forms/form.scss';
import { useForm } from "react-hook-form";
import { Button, Form, Container, Card, CardBody, CardTitle } from 'react-bootstrap';
import createUserWithEmail from '../../../services/login/createUser';
import InputMask from 'react-input-mask';


type Inputs = {
    nome: string;
    email: string;
    telefone: string;
    senha: string;
    confsenha: string;
    whatsapp: boolean;
    prestador: boolean;
};

async function createUserAndStoreData(data: Inputs) {
    await createUserWithEmail(data)
}

const handleAuthentication = (data: Inputs) => {
    createUserAndStoreData(data)
};

function LoginCadastro() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<Inputs>({
        defaultValues: {
            nome: "",
            email: "",
            telefone: "",
            senha: "",
            confsenha: "",
            whatsapp: false,
        }
    });

    const senha = watch("senha");

    const confsenha = watch("confsenha");

    const validateSenha = (value: string) => value === senha || "As senhas não coincidem";
    const validateConfsenha = (value: string) => value === confsenha || "As senhas não coincidem";

    return (


        <Card className="formLogin">
            <Link to={'/'}>
                <FaChevronLeft className="backButton" />
            </Link>

            <CardTitle className="cartTitle">
                Criar Conta
            </CardTitle>
            <CardBody>
                <Form onSubmit={handleSubmit(handleAuthentication)}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Nome Completo</Form.Label>
                        <Form.Control
                            type="text"  
                            {...register("nome", { required: true })}
                            className={errors.nome ? 'is-invalid' : ''}
                        />

                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            type="email" 
                            {...register("email", { required: true })}
                            className={errors.email ? 'is-invalid' : ''}

                        />

                    </Form.Group>

                    <Form.Group controlId="formBasicPhone">
                        <Form.Label>Telefone Celular</Form.Label>
                        {/* <InputMask
                            mask="(99) 99999-9999"
                            maskChar="_"  */}
<Form.Control
type='tel'
                            {...register("telefone", {
                                required: true,
                                validate: (value) => /\d/g.test(value) || "Telefone deve conter apenas números",
                            })}
                            className={errors.telefone ? 'is-invalid' : ''}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicWhatsApp">
                        <Form.Check
                            type="checkbox"
                            label="Este número tem WhatsApp"
                            {...register("whatsapp")}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            type="password" 
                            {...register("senha",
                                {
                                    required: true,
                                    validate: validateSenha
                                })}
                            className={errors.senha ? 'is-invalid' : ''}

                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Confirmar Senha</Form.Label>
                        <Form.Control
                            type="password"
                            
                            {...register("confsenha", {
                                required: true,
                                validate: validateConfsenha,
                            })}
                            className={errors.confsenha ? 'is-invalid' : ''}

                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicWhatsApp">
                        <Form.Check
                            type="checkbox"
                            label="Sou prestador de serviços"
                            {...register("prestador")}
                        />
                    </Form.Group>

                    <Container id="submitButton">
                        <Button type="submit"> Cadastrar</Button>
                    </Container>
                </Form>
            </CardBody>
        </Card>
    );
}

export default LoginCadastro;
