import React, {ReactNode} from 'react'
import styles from  './BtnCadastrar.module.scss';

interface BtnCadastrarProps {
    children: ReactNode
}

function BtnCadastrar({children}:BtnCadastrarProps) {
  return (
    <button className={styles.btnCadastrar}>
        {children}
    </button>
  )
}

export default BtnCadastrar