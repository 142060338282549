import React from 'react'
import ops from './assets/Ops.svg';
import styles from './PaginaDeErro.module.scss';
import { useNavigate } from 'react-router-dom';

function PaginaDeErro() {
  const ir = useNavigate();
  return (    
    <section className={styles.erro}>
        <button className={styles.erro__BtnErro} onClick={() => ir('/')}>Voltar ao Inicio !</button>
        <img src={ops} alt="Kraken" />
    </section>
  )
}

export default PaginaDeErro