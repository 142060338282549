import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import "../../../styles/forms/form.scss";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Container,
  Card,
  CardBody,
  CardTitle,
} from "react-bootstrap";
import { useLogin } from "hooks/useLogin";

type Inputs = {
  email: string;
  senha: string;
};

function LoginEntrar() {
  const ir = useNavigate();
  const {loginUserWithEmailAndPassword }=useLogin()

  

  async function login(data: any) {
    const response = await loginUserWithEmailAndPassword(data);
    if (response) {
      ir("/home");
      requestFullScreen() 
    } else {
      alert("Erro ao fazer login:Verifique seu email ou sua senha")
    }
  }
  const handleAuthentication = (data: Inputs) => {
    login(data);
  };
  
// verifica se tem login e senha cadastrado do ultimo login no sessionStorage, se tiver loga direto
  React.useEffect(() => {
    const ultimoUser = sessionStorage.getItem('userLogado');
      if(ultimoUser){
        ir('/home')
      }
  },[ir])  


  function requestFullScreen() {
    document.documentElement.requestFullscreen();
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      email: "",
      senha: "",
    },
  });

  return (
    <Card className="formLogin">
      <Link to={"/"}>
        <FaChevronLeft className="backButton" />
      </Link>

      <CardTitle className="cartTitle">Entrar</CardTitle>
      <CardBody>
        <Form onSubmit={handleSubmit(handleAuthentication)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email" 
              {...register("email", { required: true })}
            />
            {errors.email && <p className="text-danger">Campo obrigátorio!</p>}
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              type="password" 
              {...register("senha", { required: true })}
            />
            {errors.senha && <p className="text-danger">Campo obrigátorio!</p>}
          </Form.Group>
          <div id="fgtpwd">
            Esqueceu sua senha? &nbsp;
            <Link to={"/esqueciminhasenha"}>
              <u>Clique aqui</u>
            </Link>
          </div>
          <Container id="submitButton">
            <Button variant="primary" type="submit">
              Entrar
            </Button>
          </Container>
        </Form>
      </CardBody>
    </Card>
  );
}

export default LoginEntrar;
