 import {
  FBAuth,
  FBFirestore,
 
} from "../firebaseApiKey";
import { addDoc, collection } from "firebase/firestore";
import { storage } from 'services/firebaseApiKey';  
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
 

export default async function createServico(data: any) {

  let userData = JSON.parse(sessionStorage.userLogado) 

  let confradeNome = userData.displayName 
let imagensExtra = []
  if (data.imagemExtra.length > 0){
    try {
      for (let i = 0; i < data.imagemExtra.length; i++){
    const imageRef = ref(storage, 'images/' + data.imagemExtra[i].name);
 
     await uploadBytes(imageRef, data.imagemExtra[i]);
     imagensExtra[i] = await getDownloadURL(imageRef);
    }
    } catch (e) {
      console.log(e)
     }
  }
  const tipo = data.tipo  ;

 debugger
  try {
     await addDoc(collection(FBFirestore, "anuncios"), {
      titulo:  data.nome,
      descricao:  data.descricao,
      whatsapp:  data.whatsapp,
      telefone:  data.telefone,
      numerocomercial:  data.telefone,
      cidade:  data.endereco,
      cep:  data.endereco,
      confradeNome: confradeNome,
      endereco:  data.endereco,
      mostraEndereco:  true,
      categoria:  data.categories.split(":")[0],
      img:  data.image,
      imgExtra: imagensExtra,
      palavrasChave: data.tags,
      aprovado: false,
      aprovadoPor: "",
      tipo: tipo 


    });
    alert(`${tipo} criado com sucesso`);
  } catch (e) {
    // alert("Erro ao tentar criar dados \n" + e);
    console.log(e)
  }
}
