import styles from "./CardValidacaoUsuario.module.scss";
import IProdutoServico from "interfaces/IProdutoServico";
import Iuser from "interfaces/IUser"
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface ICArdValidacaoProps extends Iuser {
  aprovarCard: (cardId: string) => void;
  reprovarCard: (cardId: string) => void;
  recarregar: number;
  setRecarregar: (e: number) => void;
}

function CardValidacaoUsuario({
  id,
  displayName,
  email,
   admin,
  prestador,
  telefone,
  whatsapp,
  created,
  aprovarCard,
  reprovarCard,
  recarregar,
  setRecarregar,
}: ICArdValidacaoProps) {
  const aprovar = (id: string) => {
    aprovarCard(id);
    setRecarregar(recarregar + 1);
  };
  const reprovar = (id: string) => {
    reprovarCard(id);
    setRecarregar(recarregar + 2);
  };
  const ir = useNavigate();

  return (
    <section key={id} className={styles.card}>
      <header>{displayName}</header>
      <div className={styles.card__body}>
        <p className={styles.card__body__email}>E-mail: {email}</p>

       </div>
        <span className={styles.card__body__email}>Telefone: {telefone}</span>
        {whatsapp && 
         <span className={styles.card__body__email}>Whatsapp: {telefone}</span>
        }
       {
        prestador &&
       <span className={styles.card__prestador}>Prestador</span>
       }
     
 
      <nav>
  
        <button onClick={() => reprovar(id)}>
          <AiOutlineClose />
        </button>
        <button onClick={() => aprovar(id)}>
          <AiOutlineCheck />
        </button>
        
      </nav>
    </section>
  );
}

export default CardValidacaoUsuario;
