import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import BtnPadrao from '../../../component/BtnPadrao'
import BtnCadastrar from '../../../component/BtnCadastrar'
import img from '../../../assets/imgs/Confrarqui_logojpeg.jpeg'
import styles from './Login.module.scss';
import {FcGoogle} from 'react-icons/fc'
import {FaFacebookF} from "react-icons/fa"
import { useLogin } from 'hooks/useLogin';
import { UserContext } from 'contexts/UserContext';
 
function Login() {
  const ir = useNavigate();
  const { createUserWithFacebook, createUserWithGoogle } = useLogin()
  const {loggedIn}= React.useContext(UserContext)

  async function login(using: 'facebook' | 'google') {
    let response
    switch (using) {
      case 'facebook':
        response = await createUserWithFacebook()

        break
      case 'google':
        response = await createUserWithGoogle();

        break
      default:
        break
    }
    if (response) {
      ir("/home");
    }
  }
  if (loggedIn) {
    ir('/home')
  }
  return (
    <div className={`${styles.login}`}>
      <img 
      src={img} 
      alt='logo' 
      className='logo' 
      ></img>
      <div>
      <Link to="/registrarse">
      <BtnCadastrar>Não tem conta,&nbsp;<u>clique aqui.</u></BtnCadastrar>
      </Link>
      <Link to="/entrar">
        <BtnPadrao type="button">Entrar</BtnPadrao>
        </Link>
        <div className={styles.alternativeLogin}>
          <button className={styles.alternativeLogin__google} onClick={()=>login('google')}><FcGoogle/></button>
          <button className={styles.alternativeLogin__facebook} onClick={()=>login('facebook')}><FaFacebookF/></button>
        </div>
      </div>
    </div>
  )
}

export default Login