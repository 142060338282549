import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from './BtnRetorno.module.scss';

interface BtnRetornoProps {
  local?: string
}

function BtnRetorno({local}:BtnRetornoProps) {
  const voltar = useNavigate();
  const ir = useNavigate();
  
  return (
    <button className={styles.btnRetorno}>
      <FaChevronLeft onClick={() => local ? ir(local) : voltar(-1)} />
    </button>
  );
}

export default BtnRetorno;
