import { UserContext } from "contexts/UserContext";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import IAuth from "interfaces/IAuth";
import React from "react";
import { getAuth } from "firebase/auth";

import {
  FBAuth,
  facebookProvider,
  googleProvider,
} from "services/firebaseApiKey";
import { DocumentSnapshot, doc, getDoc, setDoc } from "firebase/firestore";
import { FBFirestore } from "../services/firebaseApiKey";
import IUser from "interfaces/IUser";

export function useLogin() {
  const { setUser, setLoading, setError, setLoggedIn } =
    React.useContext(UserContext);

  async function getUserPhoneAndName(user: any) {
    const userRef = doc(FBFirestore, "usuarios", user.uid);
    const userDoc = await getDoc(userRef);
    const phone = await userDoc.get("telefone");
    const displayName = await userDoc.get("displayName");
    const admin = await userDoc.get("admin")
    const prestador = await userDoc.get("prestador")
    return { phone, displayName, admin, prestador };
  }

  const loginUserWithEmailAndPassword = async ({ email, senha }: IAuth) => {
    try {
      setLoading(true);
      var dados;
      await signInWithEmailAndPassword(FBAuth, email, senha);
      if (FBAuth.currentUser) {
        var userData = await getUserPhoneAndName(FBAuth.currentUser);
        const accessToken = await getAuth().currentUser?.getIdToken();
        const userDocRef = doc(FBFirestore, "usuarios", FBAuth.currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef) as DocumentSnapshot<IUser>

        setUser(userDocSnapshot.data());
        dados = {
          
          accessToken: accessToken,
          displayName: userData.displayName,
          phoneNumber: userData.phone,
          prestador: userData.prestador,
          admin: userData.admin,
        };
        console.log(dados)
        console.log(FBAuth.currentUser)

        setLoggedIn(true);
        sessionStorage.setItem("userLogado", JSON.stringify(userDocSnapshot.data()));
        return true;
      }
    } catch (err: any) {
      setError(err);
      alert("Erro ao fazer login: Verifique seu email e sua senha");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const createUserWithGoogle = async () => {
    try {
      setLoading(true);
      await signInWithPopup(FBAuth, googleProvider);
      if (FBAuth.currentUser) {
        const user = FBAuth.currentUser;
        const userData = {
          created: "google",
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          telefone: user.providerData[0].phoneNumber,
          whatsapp: user.providerData[0].phoneNumber? true : false,
          prestador: false,
          prestadorAprovado: false,
          admin: false,
        };
        // Store the user data in Firestore
        const userDocRef = doc(FBFirestore, "usuarios", user.uid);
        const userDocSnapshot = await getDoc(userDocRef) as DocumentSnapshot<IUser>

        if (!userDocSnapshot.exists()) {
          await setDoc(userDocRef, userData);
        }
    
        setUser(userDocSnapshot.data());
        setLoggedIn(true);
        sessionStorage.setItem("userLogado", JSON.stringify(userDocSnapshot.data()));
        return "login";
      }
    } catch (err: any) {
      setError(err);
      alert("Erro ao criar usuario: " + err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const createUserWithFacebook = async () => {
    try {
      setLoading(true);
      // Sign in with Facebook
      await signInWithPopup(FBAuth, facebookProvider);
      
      if (FBAuth.currentUser) {
        const user = FBAuth.currentUser;
        const userData = {
          created: "facebook",
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          telefone: user.providerData[0].phoneNumber,
          whatsapp: user.providerData[0].phoneNumber? true : false,
          prestador: false,
          prestadorAprovado: false,
          admin: false,
        };
    
        // Store the user data in Firestore
        const userDocRef = doc(FBFirestore, "usuarios", user.uid);
        const userDocSnapshot = await getDoc(userDocRef) as DocumentSnapshot<IUser>;

        if (!userDocSnapshot.exists()) {
          await setDoc(userDocRef, userData);
        }
    
        setUser(userDocSnapshot.data());
        setLoggedIn(true);
        sessionStorage.setItem("userLogado", JSON.stringify(userDocSnapshot.data()));
    
        return "login";
      }
    } catch (err: any) {
      setError(err);
      alert("Erro ao criar usuario: " + err);
      return null;
    } finally {
      setLoading(false);
    }
  }

  return {
    loginUserWithEmailAndPassword,
    createUserWithGoogle,
    createUserWithFacebook,
  };
}

