import React, { useState } from "react";
import styles from "./Cadastro.module.scss";
import BtnRetorno from "../../component/BtnRetorno";
import Titulo from "../../component/Titulo";
import FormCadastro from "./FormCadastro";

import FormServico from "./FormServico";
import FormProduto from "./FormCadastro";

function Cadastro() {
  const [selectedOption, setSelectedOption] = useState('Servico');

  const handleRadioChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  return (
    <section className={styles.cadastro}>
      <BtnRetorno />
      <div className={styles.cadastro__form}>
          <FormCadastro />
      </div>
    </section>
  );
}

export default Cadastro;
