import React from 'react'
import { Link } from 'react-router-dom'
import { FaChevronLeft } from "react-icons/fa";
import '../../../styles/forms/form.scss'
import { useForm } from "react-hook-form";
import { Button, Form, Container, Card, CardBody, CardTitle } from 'react-bootstrap';
import resetPassword from '../../../services/login/resetPasswordUser';

type Inputs = {
  email: string
};

async function resetUserPassword(email: any) {
  email = email.email
 await resetPassword(email)
}

const handleAuthentication = (data: Inputs) => {
  resetUserPassword(data)
};

function LoginForgotPassword() {

  const {
    register,
    handleSubmit,

    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      email: ""
    }
  });

  return (
    <Card className='formLogin'>
      <Link to={'/entrar'}>
        <FaChevronLeft className='backButton' />
      </Link>

      <CardTitle className='h5'>
        Esqueci minha Senha
      </CardTitle>
      <CardBody>
        <Form onSubmit={handleSubmit(handleAuthentication)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email" 
              {...register("email", { required: true })}
            />
            {errors.email && <p className="text-danger">Campo obrigátorio!</p>}
          </Form.Group>

          <Container id='submitButton'>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Container>

        </Form>
      </CardBody>
    </Card>
  )
}

export default LoginForgotPassword