import React from 'react'
import styles from './Ordenador.module.scss';
import { HiBarsArrowDown } from 'react-icons/hi2';

type Order = "az" | 'za' | 'data+' | "data-"

interface OrdenadorProps {
  onOrderByChange: (order: Order) => void
  searchFocused: boolean
}


function Ordenador({ onOrderByChange, searchFocused }: OrdenadorProps) {
  const [dropdownActive, setDropdownActive] = React.useState(false)
  const ordenadorRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ordenadorRef.current && !ordenadorRef.current.contains(event.target as Node)) {
        // Clicked outside of the component, so close the dropdown
        setDropdownActive(false);
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  function handleOrderByChange(order: Order) {
    onOrderByChange(order)
  }

  return (
    <nav className={`${styles.ordenador} ${searchFocused && styles.ordenadorHidden}`}  onClick={()=>setDropdownActive(!dropdownActive)} ref={ordenadorRef}>
        <div className={styles.ordenador__item} onClick={()=>setDropdownActive(!dropdownActive)}>
        <HiBarsArrowDown/>
            <div className={`${styles.ordenador__item__dropdown} ${dropdownActive&& styles.ordenador__item__dropdown__active}`}>
                <div>
                    <button onClick={()=> handleOrderByChange('az')}>A - Z</button>
                    <button onClick={()=> handleOrderByChange('za')}>Z - A</button>
                    {/* <button onClick={()=> handleOrderByChange('data+')}>Data +</button>
                    <button onClick={()=> handleOrderByChange('data-')}>Data -</button> */}
                </div>
            </div>
        </div>
        <div className={styles.teste__underline}></div>
    </nav>
  )
}

export default Ordenador